html, body {
  height: 100%;
  min-width: $page-min-width;
}

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

#page-header {
  .navbar-brand {
    margin-right: 0;
  }

  .navbar-brand {
    padding-top: 0;
    padding-bottom: 0;

    img {
      height: 3.25rem;
    }
  }

  .navbar-nav {
    .nav-item {
      .nav-link {
        padding: 0.5rem 1rem;
      }

      &.active {
        & > a {
          background-color: $primary;
          color: white;
        }
      }
    }
  }
}

#page-body {
  flex: 1;
  padding-bottom: 1rem;
}

#page-footer {

  a {
    color: #fff;
  }
}

//#### Minimal page layout
.centered-box-aligner {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding-top: 2rem;
  padding-bottom: 2rem;
  flex-direction: column;
}

.auth-form {
  background-color: #eee;
  padding: 1rem;
  max-width: 100%;
  width: 400px;

  .input-group-append {
    label {
      transition: background-color .2s;
    }

    svg {
      height: 1rem;
      width: 1rem;
      transition: fill .2s;
    }

    .fa-stack {
      width: 1rem;
      height: 1rem;
    }
  }

  .form-control:focus {
    box-shadow: none;

    & + .input-group-append {
      svg {
        path {
          fill: #fff;
        }

      }

      label {
        background-color: #005197;
      }
    }
  }

  &.auth-form--large {
    width: 45rem;
  }
}

.page-minimal--content-box {
  background-color: #eee;
  padding: 2rem 1rem;
  text-align: center;
  width: 100%;

  & > :last-child {
    margin-bottom: 0;
  }
}

.page-minimal--logo {
  height: 4rem;
  display: inline-block;
  margin-left: .5rem;
  margin-right: .5rem;
}

.page-minimal--title {
  text-align: center;
  font-size: 1.8rem;
}

.page-minimal--subtitle {
  text-align: center;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.page-minimal--footer-note {
  text-align: center;
  font-size: .9rem;
  color: #888;
  margin-top: .5rem;
}

.page-minimal--footer-note a {
  color: #888;
}


